<template>
  <div id="blocklist-monitoring">
    <v-row class="d-flex justify-center">
      <v-col cols="12" lg="10" md="10">
        <blocklist-monitoring-usage />
        <card-widget>
          <template #cardBody>
            <v-row>
              <v-col cols="12" lg="9" md="9">
                <h1
                  class="text--primary secondary--font font-weight-large"
                  v-text="
                    $dictionary.app.accountSettings.blocklistMonitoring.title
                  "
                />
                <div
                  class="text--secondary pt-2 subtitle-1"
                  v-text="
                    $dictionary.app.accountSettings.blocklistMonitoring.subTitle
                  "
                />
              </v-col>
              <v-col cols="12" lg="3" md="3" align="end">
                <tooltip
                  width="30%"
                  :name="
                    $dictionary.app.accountSettings.blocklistMonitoring.warning
                  "
                  :disabled="!isAddProfileDisabled"
                  :type="$appConfig.tooltip.type.dark"
                >
                  <template #default="{ on, attrs }">
                    <div
                      v-on="on"
                      v-bind="attrs"
                      :class="
                        isAddProfileDisabled || !isAdmin
                          ? 'cursor-not-allowed'
                          : ''
                      "
                    >
                      <v-btn
                        link
                        :to="
                          `/settings/account/${selectedAccountId}/blocklist-profile`
                        "
                        color="primary"
                        v-text="'Add Profile'"
                        class="font-weight-bold"
                        v-track="'add-blocklist-monitoring-profile'"
                        :disabled="isAddProfileDisabled || !isAdmin"
                      />
                    </div>
                  </template>
                </tooltip>
              </v-col>
            </v-row>
            <div class="mt-4">
              <blocklist-monitoring-table
                :is-admin="isAdmin"
                :isLoading="isLoading"
                :data="blocklistProfiles"
                @edit-profile="profileNavigator"
                @update-records="fetchBlocklistProfiles"
              />
            </div>
          </template>
        </card-widget>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CardWidget from "@/components/widgets/CardWidget.vue";

import Tooltip from "@/components/shared/Tooltip.vue";
import BlocklistMonitoringTable from "@/components/tables/BlocklistMonitoringTable.vue";
import BlocklistMonitoringUsage from "@/components/tabs/BlocklistMonitoringTab/BlocklistMonitoringUsage.vue";

/**
 * Blocklist monitoring tab
 */
export default {
  name: "BlocklistMonitoringTab",
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      updateResources: false,
      isLoading: false,
    };
  },
  /**
|--------------------------------------------------
| Components
|--------------------------------------------------
*/
  components: {
    Tooltip,
    CardWidget,
    BlocklistMonitoringUsage,
    BlocklistMonitoringTable,
  },
  /**
  |--------------------------------------------------
  | Watches component properties
  |--------------------------------------------------
  */
  watch: {
    selectedAccountId() {
      this.updateResources = !this.updateResources;
      this.fetchBlocklistProfiles();
    },
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      isAdmin: "auth/isAdmin",
      selectedAccountId: "ui/selectedAccountId",
      blocklistProfiles: "blocklist/blocklistProfiles",
    }),
    /**
     * Whether selected (current) account blocklist profiles Quota is finished or not
     */
    isAddProfileDisabled() {
      return this.blocklistProfiles && this.blocklistProfiles.length >= 10;
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      getBlocklistProfiles: "blocklist/getBlocklistProfiles",
    }),
    /**
     * Fetch list of blocklist monitoring profiles
     @todo Integrate get api to fetch profiles when it is available
     */
    async fetchBlocklistProfiles() {
      try {
        this.isLoading = true;
        const payload = { account_id: this.selectedAccountId };
        await this.getBlocklistProfiles(payload);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Navigates a user to selected blocklist profile
     */
    profileNavigator({ id }) {
      this.$router.push(
        `/settings/account/${this.selectedAccountId}/blocklist-profile/${id}`
      );
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.fetchBlocklistProfiles();
  },
};
</script>
