var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"blocklist-monitoring"}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"12","lg":"10","md":"10"}},[_c('blocklist-monitoring-usage'),_c('card-widget',{scopedSlots:_vm._u([{key:"cardBody",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"9","md":"9"}},[_c('h1',{staticClass:"text--primary secondary--font font-weight-large",domProps:{"textContent":_vm._s(
                  _vm.$dictionary.app.accountSettings.blocklistMonitoring.title
                )}}),_c('div',{staticClass:"text--secondary pt-2 subtitle-1",domProps:{"textContent":_vm._s(
                  _vm.$dictionary.app.accountSettings.blocklistMonitoring.subTitle
                )}})]),_c('v-col',{attrs:{"cols":"12","lg":"3","md":"3","align":"end"}},[_c('tooltip',{attrs:{"width":"30%","name":_vm.$dictionary.app.accountSettings.blocklistMonitoring.warning,"disabled":!_vm.isAddProfileDisabled,"type":_vm.$appConfig.tooltip.type.dark},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:_vm.isAddProfileDisabled || !_vm.isAdmin
                        ? 'cursor-not-allowed'
                        : ''},'div',attrs,false),on),[_c('v-btn',{directives:[{name:"track",rawName:"v-track",value:('add-blocklist-monitoring-profile'),expression:"'add-blocklist-monitoring-profile'"}],staticClass:"font-weight-bold",attrs:{"link":"","to":("/settings/account/" + _vm.selectedAccountId + "/blocklist-profile"),"color":"primary","disabled":_vm.isAddProfileDisabled || !_vm.isAdmin},domProps:{"textContent":_vm._s('Add Profile')}})],1)]}}])})],1)],1),_c('div',{staticClass:"mt-4"},[_c('blocklist-monitoring-table',{attrs:{"is-admin":_vm.isAdmin,"isLoading":_vm.isLoading,"data":_vm.blocklistProfiles},on:{"edit-profile":_vm.profileNavigator,"update-records":_vm.fetchBlocklistProfiles}})],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }