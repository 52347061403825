var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"block-list-monitoring-table"}},[_c('v-data-table',{attrs:{"items":_vm.profiles,"headers":_vm.headers,"loading":_vm.isLoading,"options":_vm.pagination,"disable-pagination":"","hide-default-footer":"","no-data-text":_vm.$dictionary.app.common.blocklistMonitoring.noData,"loading-text":_vm.$dictionary.app.common.blocklistMonitoring.loading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.name,"text-class":"text-capitalize"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'cursor-not-allowed': !_vm.isAdmin }},[_c('v-switch',{directives:[{name:"track",rawName:"v-track",value:('toogle-blocklist-monitoring-profile-status'),expression:"'toogle-blocklist-monitoring-profile-status'"}],staticClass:"mt-0",attrs:{"hide-details":"","color":"primary","disabled":!_vm.isAdmin,"loading":item.isLoading,"input-value":item.isActive},on:{"change":function($event){return _vm.onProfileStatusChange(item, $event)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})],1)]}},{key:"item.weekly_lookups",fn:function(ref){
var item = ref.item;
return [_c('table-matrics-row',{attrs:{"text":item.weekly_lookups || '0'}})]}},{key:"item.domainIps",fn:function(ref){
var item = ref.item;
return [_c('text-ellipsis',{attrs:{"text":item.domainIps,"text-class":"text-lowercase"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('table-action-menu-button',{attrs:{"item":item,"show-pointer":"","actions":_vm.tableActions}})]}}])}),_c('delete-record-dialog',{attrs:{"show-warning":"","title":_vm.deleteProfileDialog.title,"dialog":_vm.deleteProfileDialog.dialog,"sub-title":_vm.deleteProfileDialog.subtitle},on:{"close":_vm.onCloseDeleteProfileDialog},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('div',{staticClass:"delete-actions"},[_c('v-btn',{staticClass:"text--primary font-weight-bold secondary--font mr-2",attrs:{"text":""},domProps:{"textContent":_vm._s('Cancel')},on:{"click":function($event){$event.stopPropagation();return _vm.onCloseDeleteProfileDialog($event)}}}),_c('v-btn',{staticClass:"font-weight-bold secondary--font",attrs:{"color":"primary","loading":_vm.isProfileDeleting},on:{"click":function($event){$event.stopPropagation();return _vm.deleteProfile()}}},[_vm._v(" Delete ")])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }