<template>
  <v-menu left bottom offset-y>
    <template #activator="{ on, attrs }">
      <slot
        v-if="hasActivatorSlot"
        name="menuActivatior"
        :on="on"
        :attrs="attrs"
      />
      <v-btn v-else icon v-on="on">
        <v-icon v-bind="attrs" class="text--secondary">
          mdi-dots-vertical
        </v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <template v-for="{ name, icon, onClick, disabled, ...action } in actions">
        <v-list-item
          :key="name"
          @click="onClick(item)"
          :disabled="disabled"
          v-track="action.id"
          :class="{ 'cursor-not-allowed': showPointer && disabled }"
        >
          <v-icon
            v-text="icon"
            :disabled="disabled"
            class="mr-3 headline text--secondary"
          />
          <v-list-item-title class="primary--font body-2 pr-3" v-text="name" />
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { hasProp } from "@/utils/common.utils";
/**
 * Table menu actions button
 * @author {Jatin Kamboj}
 */
export default {
  name: "TableActionMenuButton",
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    /**
     * Array of action buttons to be rendered
     * @example [{ name: "Edit", icon: "mdi-pencil", onClick: () => {} }]
     */
    actions: {
      type: Array,
      required: true,
      default: () => [{ name: "Edit", icon: "mdi-pencil", onClick: () => {} }],
    },
    showPointer: { type: Boolean, default: false },
    /**
     * Current item where the component was rendered
     */
    item: { type: [Object, Array, String, Number] },
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    /**
     * Determines if {{menuActivatior}} slot have been used by parent component
     * @returns {Boolean} is lot used or not
     */
    hasActivatorSlot() {
      return hasProp(this.$slots, "menuActivatior");
    },
  },
};
</script>
