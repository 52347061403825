<template>
  <div class="delete-actions">
    <v-btn
      text
      @click.stop="onClose"
      class="text--primary font-weight-bold secondary--font"
    >
      Cancel
    </v-btn>
    <v-btn
      text
      @click.stop="onDelete"
      class="text--primary font-weight-bold secondary--font"
      :loading="isDeleting"
    >
      {{ btnText }}
    </v-btn>
  </div>
</template>

<script>
/**
 * Delete action buttons
 * @description Buttons to be shown in the dialog notification to delete a record or close dialog box
 */
export default {
  name: "DeleteActionButtons",
  /**
   * -------------- Custom events -------------
   */
  emits: ["delete", "close"],
  /**
   * -------------- Props -------------
   */
  props: {
    isDeleting: { type: Boolean, default: false },
    /**
     * Submit button text
     */
    btnText: { type: String, default: "Delete" },
  },
  /**
   * -------------- Methods -------------
   */
  methods: {
    /**
     * Delete button click event handler
     * @emits delete
     */
    onDelete(event) {
      this.$emit("delete", event);
    },
    /**
     * Cancel button click event handler
     * @emits close
     */
    onClose(event) {
      this.$emit("close", event);
    },
  },
};
</script>
