function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.hasActivatorSlot)?_vm._t("menuActivatior",null,{"on":on,"attrs":attrs}):_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',_vm._b({staticClass:"text--secondary"},'v-icon',attrs,false),[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.actions),function(ref){
var name = ref.name;
var icon = ref.icon;
var onClick = ref.onClick;
var disabled = ref.disabled;
var rest = objectWithoutProperties( ref, ["name", "icon", "onClick", "disabled"] );
var action = rest;
return [_c('v-list-item',{directives:[{name:"track",rawName:"v-track",value:(action.id),expression:"action.id"}],key:name,class:{ 'cursor-not-allowed': _vm.showPointer && disabled },attrs:{"disabled":disabled},on:{"click":function($event){return onClick(_vm.item)}}},[_c('v-icon',{staticClass:"mr-3 headline text--secondary",attrs:{"disabled":disabled},domProps:{"textContent":_vm._s(icon)}}),_c('v-list-item-title',{staticClass:"primary--font body-2 pr-3",domProps:{"textContent":_vm._s(name)}})],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }