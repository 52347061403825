<template>
  <dialog-box width="500px" @close="closeDialog" :dialog="dialog">
    <v-card class="pa-8 pb-5">
      <slot v-if="hasDefaultSlot"></slot>
      <template v-else>
        <v-card-title class="headline pa-0 pb-4">
          <h6 class="text-h6 secondary--font text--primary font-weight-large ">
            {{ title }}
          </h6>
        </v-card-title>
        <v-card-text class="pa-0 ">
          <div class="body-2 text--secondary">
            {{ subTitle }}
          </div>
          <div
            class="body-2 text--primary font-weight-large pt-4"
            v-if="showWarning"
          >
            {{ warning }}
          </div>
        </v-card-text>
      </template>
      <br />
      <v-card-actions justify-end class="pa-0">
        <v-spacer />
        <slot name="buttons">
          <delete-action-buttons
            :is-deleting="isLoading"
            @close="closeDialog"
            @delete="onDelete"
            :btn-text="btnText"
          />
        </slot>
      </v-card-actions>
    </v-card>
  </dialog-box>
</template>

<script>
import DeleteActionButtons from "./DeleteActionButtons.vue";
import DialogBox from "./DialogBox.vue";

/**
 * Dialog box to show notification to user to delete a record
 */
export default {
  name: "DeleteRecordDialog",
  /**
   * -------------- Custom events -------------
   */
  emits: ["close", "delete"],
  /**
   * -------------- Props -------------
   */
  props: {
    /**
     * @model Dialog box model
     */
    dialog: { type: Boolean, default: false, required: true },
    title: { type: String, default: "Title" },
    subTitle: { type: String, default: "Sub Title" },
    /**
     * Text of the button to be shown
     */
    btnText: { type: String, default: "Delete" },
    /**
     * Adds loader on the delete button
     */
    isLoading: { type: Boolean, default: false },
    showWarning: { type: Boolean, default: false },
    warning: { type: String, default: "Warning: You cannot undo this action." },
  },
  /**
   * -------------- Components -------------
   */
  components: {
    DialogBox,
    DeleteActionButtons,
  },
  /**
   * -------------- Methods -------------
   */
  methods: {
    /**
     * Emits event in parent component to close dialog box
     * @emits close
     */
    closeDialog(value) {
      this.$emit("close", value);
    },
    /**
     * Delete button handler
     * @description Emits event in parent component to close dialog box
     * @emits delete
     */
    onDelete(event) {
      this.$emit("delete", event);
    },
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    /**
     * Determines if default slot is being used to render custom html
     */
    hasDefaultSlot() {
      return Object.hasOwnProperty.call(this.$slots, "default");
    },
  },
};
</script>
