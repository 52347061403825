<template>
  <div class="mb-6" id="blocklist-monitoring-usage">
    <card-widget>
      <template #cardBody>
        <div id="blocklist-monitoring-usage__wrapper" class="pa-8">
          <v-row no-gutters>
            <v-col cols="12" xl="2" lg="3" md="3" class="flex-shrink-1">
              <h1 class="secondary--font font-weight-large">Usage</h1>
            </v-col>
            <v-col cols="12" xl="10" lg="9" md="9">
              <div class="d-flex align-center">
                <v-progress-linear
                  id="blocklist-quota-progress"
                  rounded
                  color="darkBlue"
                  :value="monthlyQuotaExhausted"
                  height="16px"
                  class="mr-4"
                />
                <h1
                  class="
                    secondary--font
                    font-weight-large
                    text-h6
                    darkBlue--text
                  "
                  v-text="`${monthlyLookupsQuota} Monthly Lookups`"
                />
              </div>

              <div class="pt-2 body-2 text--primary">
                {{ description }}
              </div>
              <div class="mt-6">
                <alert-message
                  classes="mb-0"
                  :bg-color="$appConfig.alert.colors.info"
                  :icon-color="$appConfig.alert.colors.infoIcon"
                  :message="
                    $dictionary.app.accountSettings.blocklistMonitoring.info
                  "
                >
                  <template #message>
                    <a
                      id="contact-inboxaware"
                      :href="`mailto:${$appConfig.supportEmail}`"
                      class="blue--text cursor-pointer text-decoration-none"
                      v-text="$appConfig.supportEmail"
                    >
                    </a>
                  </template>
                </alert-message>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
    </card-widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardWidget from "@/components/widgets/CardWidget.vue";
import AlertMessage from "@/components/shared/AlertMessage.vue";

/**
 * Blocklist monitoring usage for an account
 */
export default {
  name: "BlocklistMonitoringUsage",
  /**
    |--------------------------------------------------
    | Components
    |--------------------------------------------------
    */
  components: { CardWidget, AlertMessage },
  /**
  |--------------------------------------------------
  | Data properties
  |--------------------------------------------------
  */
  data() {
    return {
      monthlyExhaustedLookups: 20,
    };
  },
  /**
  |--------------------------------------------------
  | COmputed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      monthlyLookupsQuota: "ui/monthlyLookupsQuota",
    }),
    /**
     * View description message
     */
    description() {
      const { blocklistMonitoring } = this.$dictionary.app.accountSettings;

      return blocklistMonitoring.upgradePlan.replace(
        "{{quota}}",
        this.monthlyLookupsQuota
      );
    },
    /**
     * Monthly exausted quota percentage
     * @type {Number}
     */
    monthlyQuotaExhausted() {
      const { percent } = this.$options.filters;
      return Math.ceil(
        percent(this.monthlyExhaustedLookups, this.monthlyLookupsQuota)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#blocklist-monitoring-usage__wrapper {
  border-radius: 4px;
  border: 1px solid $light-grey-border;

  #blocklist-quota-progress {
    width: 250px;
  }
}
</style>
