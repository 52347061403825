<template>
  <div id="block-list-monitoring-table">
    <v-data-table
      :items="profiles"
      :headers="headers"
      :loading="isLoading"
      :options.sync="pagination"
      disable-pagination
      hide-default-footer
      :no-data-text="$dictionary.app.common.blocklistMonitoring.noData"
      :loading-text="$dictionary.app.common.blocklistMonitoring.loading"
    >
      <template #item.name="{ item }">
        <table-matrics-row :text="item.name" text-class="text-capitalize" />
      </template>
      <template #item.status="{ item }">
        <span :class="{ 'cursor-not-allowed': !isAdmin }">
          <v-switch
            class="mt-0"
            hide-details
            color="primary"
            :disabled="!isAdmin"
            v-model="item.isActive"
            :loading="item.isLoading"
            :input-value="item.isActive"
            @change="onProfileStatusChange(item, $event)"
            v-track="'toogle-blocklist-monitoring-profile-status'"
          />
        </span>
      </template>
      <template #item.weekly_lookups="{ item }">
        <table-matrics-row :text="item.weekly_lookups || '0'" />
      </template>
      <template #item.domainIps="{ item }">
        <text-ellipsis :text="item.domainIps" text-class="text-lowercase" />
      </template>
      <template #item.actions="{ item }">
        <table-action-menu-button
          :item="item"
          show-pointer
          :actions="tableActions"
        />
      </template>
    </v-data-table>
    <delete-record-dialog
      show-warning
      @close="onCloseDeleteProfileDialog"
      :title="deleteProfileDialog.title"
      :dialog="deleteProfileDialog.dialog"
      :sub-title="deleteProfileDialog.subtitle"
    >
      <template #buttons>
        <div class="delete-actions">
          <v-btn
            text
            v-text="'Cancel'"
            @click.stop="onCloseDeleteProfileDialog"
            class="text--primary font-weight-bold secondary--font mr-2"
          />
          <v-btn
            color="primary"
            :loading="isProfileDeleting"
            @click.stop="deleteProfile()"
            class="font-weight-bold secondary--font"
          >
            Delete
          </v-btn>
        </div>
      </template>
    </delete-record-dialog>
  </div>
</template>

<script>
import TableMatricsRow from "@/components/shared/TableMatricsRow.vue";
import DeleteRecordDialog from "@/components/shared/DeleteRecordDialog.vue";
import headers from "@/data/table-headers/blocklist-monitoring.headers.json";
import TableActionMenuButton from "@/components/shared/TableActionMenuButton.vue";

import { RECORD_STATUSES, RESPONSE_CODES } from "@/constants";
import { defer, isEmpty, pick } from "@/utils/common.utils";
import TextEllipsis from "@/components/shared/TextEllipsis.vue";

import { mapActions, mapGetters } from "vuex";
import {
  deleteBlocklistProfile,
  updateBlocklistProfileStatus,
} from "@/services/blocklistProfiles";

/**
 * Block list monitoring table
 */
export default {
  name: "BlocklistMonitoringTable",
  /**
  |--------------------------------------------------
  | Custom events emitted by the component
  |--------------------------------------------------
  */
  emits: ["edit-profile", "delete-profile", "update-records"],
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    TableMatricsRow,
    TableActionMenuButton,
    TextEllipsis,
    DeleteRecordDialog,
  },
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    /**
     * Is user admin of selected account or not
     */
    isAdmin: { type: Boolean, default: false },
    /**
     * Blocklist profiles list
     */
    data: { type: Array, required: true, default: () => ({}) },
    /**
     * Data is being loaded
     */
    isLoading: { type: Boolean, required: true, default: false },
  },
  /**
  |--------------------------------------------------
  | Data properties
  |--------------------------------------------------
  */
  data() {
    const dialogTitle = "Delete ’profile_name’";
    return {
      headers,
      isProfileDeleting: false,
      selectedProfile: null,
      dialogTitle,
      pagination: {
        sortDesc: [false],
        sortBy: ["name"],
      },
      deleteProfileDialog: {
        dialog: false,
        title: dialogTitle,
        subtitle: "This profile is about to be permanently deleted.",
        warning: "Warning: You cannot undo this action.",
      },
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      selectedAccountId: "ui/selectedAccountId",
    }),
    /**
     * Table row action buttons for a profile record
     */
    tableActions() {
      const disabled = !this.isAdmin;
      return [
        {
          id: "edit-blocklist-monitoring-profile-button",
          name: "Edit Profile",
          icon: "mdi-pencil",
          onClick: this.editProfile,
          disabled,
        },
        {
          id: "delete-blocklist-monitoring-profile-button",
          name: "Delete Profile",
          icon: "mdi-delete",
          disabled,
          onClick: this.onDeleteProfile,
        },
      ];
    },
    /**
     * List of blocklist monitoring profile
     * @type {Array}
     */
    profiles() {
      if (isEmpty(this.data)) return [];

      return this.data.map((profile) => ({
        ...profile,
        isLoading: false,
        domainIps: this.wrapDomainAndIps(profile),
        isActive: this.isProfileActive(profile),
      }));
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
    }),
    /**
     * @emits update-records in parent component to fetch update blocklist records
     */
    fetchRecords() {
      this.$emit("update-records");
    },
    /**
     * Computes the monitoring profile request payload
     * @param {Object} profile Monitoring profile details
     * @returns {Object} request payload
     */
    getProfilePayload(profile) {
      const data_props = [
        "id",
        "name",
        "timezone",
        "status",
        "ip_addresses",
        "domains",
      ];
      return pick(profile, data_props);
    },
    /**
     * Updates a blocklist monitoring profile details
     * @param {Object} profile Monitoring profile details
     * @param {Boolean} isActive Is status of the profile active or inactive
     */
    async updateBlocklistProfileApi(profile, isActive = true) {
      try {
        profile.isLoading = true;

        const status = isActive
          ? RECORD_STATUSES.active
          : RECORD_STATUSES.inactive;

        await updateBlocklistProfileStatus(
          this.selectedAccountId,
          profile.id,
          status
        );
        this.setSnackbar({
          value: true,
          message: isActive
            ? "Profile successfully activated."
            : "Profile successfully disabled.",
          type: this.$appConfig.snackbar.snackbarTypes.success,
        });
      } catch {
        profile.isActive = !isActive;
      } finally {
        profile.isLoading = false;
      }
    },
    editProfile(profile) {
      this.$emit("edit-profile", profile);
    },
    /**
     * Closes delete record dialog
     */
    onCloseDeleteProfileDialog() {
      this.deleteProfileDialog.dialog = false;
      this.selectedProfile = null;
      defer(() => (this.deleteProfileDialog.title = this.dialogTitle), 200);
    },
    /**
     * Delete monitoring profile dialog click handler
     * @param {Object} profile monitoring profile
     * @listens click
     */
    onDeleteProfile(profile) {
      this.selectedProfile = profile;

      this.deleteProfileDialog.dialog = true;
      this.deleteProfileDialog.title = this.dialogTitle.replace(
        "profile_name",
        profile.name
      );
    },
    /**
     * Deletes blocklist monioring profile
     * @param {Object} profile Profile to be deleted
     */
    async deleteProfile(profile = this.selectedProfile) {
      try {
        this.isProfileDeleting = true;
        const { status } = await deleteBlocklistProfile(
          this.selectedAccountId,
          profile.id
        );

        // Record have been deleted
        if (status === RESPONSE_CODES.noContent) {
          this.setSnackbar({
            value: true,
            message: `Profile successfully deleted.`,
            type: this.$appConfig.snackbar.snackbarTypes.success,
          });
        }
        this.onCloseDeleteProfileDialog();
        this.fetchRecords();
      } finally {
        this.isProfileDeleting = false;
      }
    },
    /**
     * Is profile active or not
     * @type {Boolean}
     */
    isProfileActive(profile) {
      return profile.status === RECORD_STATUSES.active;
    },
    /**
     * Profile status change handler
     * @param {Object} profile Monitoring profile object
     * @param {Boolean} val Updated status of the monitoring profile
     */
    onProfileStatusChange(profile, val) {
      this.updateBlocklistProfileApi(profile, val);
    },
    /**
     * Concats the list of domains and ip addresss into a single string
     * @returns {String} Concated domains and ip adddress
     */
    wrapDomainAndIps({ targets = [] }) {
      return targets?.join(", ") ?? "";
    },
  },
};
</script>
